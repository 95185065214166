import { Button, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput } from '@tremor/react';
import styles from './bots.module.scss';
import { IoSearchSharp } from 'react-icons/io5';
import { FiPlus } from 'react-icons/fi';
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import tableData from './bots.json';

import { useEffect, useState } from 'react';
import { baseUrl } from '../../app/shared';
import { AllBotData, Filter } from '../../interface/Bot';
import { initial_state } from '../../interface/Bot';
import axios from 'axios';
import { useAuthHeaders } from '../../app/header';
import AddBot from './addBot';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { useEnterKey } from '../../global/Componnets/utils/enterAction';
import { handleSuccess } from '../../global/SuccessHandling/successhandler';

function Bots() {
  const [botTableData, setBotTableData]=useState<AllBotData>();
  const [tableFilter, setTableFiler]=useState<Filter>(initial_state);
  const {data_header}=useAuthHeaders();
  const [view, setView]=useState(false);

  async function getBotDetails({ bot_name, page, limit }: Filter) {
    try {
      var url = `${baseUrl}/api/admin/get-all-bots?page=${page}&limit=${limit}`;
      if (bot_name) url += `&bot_name=${encodeURIComponent(bot_name)}`;

      const response = await axios.get(url, data_header);
      if(response.status===200 || response.status===201){
        setBotTableData(response.data);
        const botData: AllBotData = response.data;
              setBotTableData(botData);
      }
      else{
        throw new Error(`Failed to fetch bot details: ${response.statusText}`);
      }
    } catch (error) {
      handleError(error);
    }
  }

  useEnterKey(() => getBotDetails(tableFilter));

  useEffect(() => {
    getBotDetails(initial_state);
  }, []);

  useEffect(()=>{
    tableFilter.bot_name==='' && getBotDetails(initial_state);
  },[tableFilter.bot_name])

  return (
    <div className={styles.bots}>
      <div className={styles.filters}>
        <div className={styles.search}>
          <TextInput onValueChange={(value)=>setTableFiler({...tableFilter, bot_name:value})} placeholder='Bot Name'/>
          <Button onClick={()=>getBotDetails(tableFilter)} variant='secondary' color={'orange'} icon={IoSearchSharp}>Search</Button>
        </div>
        <Button onClick={()=>setView(true)} variant='secondary' color={'orange'} icon={FiPlus}>Add Bot</Button>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.pagination}><p>Showing page {botTableData?.current_page || 1} of {botTableData?.total_pages || 1}</p> 
          <FaAngleLeft onClick={() => botTableData?.current_page !== 1 && getBotDetails({bot_name:tableFilter.bot_name ,limit:10,page:(botTableData?.current_page || 1)-1})} />
          <FaAngleRight onClick={() => botTableData?.current_page !== botTableData?.total_pages && getBotDetails({bot_name:tableFilter.bot_name ,limit:10,page:(botTableData?.current_page || 1)+1})} />
        </div>
        <div className={styles.table}>
                    <Table className={styles.mainTable}>
                        <TableHead className={styles.tableHead}>
                            <TableRow  className={styles.tableRow}>
                                <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Bot</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Instrument</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Type</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60" , textAlign:"center"}}>Users Subscribed</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Status</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                botTableData?.data.map((value, index)=>(
                                    <TableRow key={index}>
                                        <TableCell>{value.bot_name}</TableCell>
                                        <TableCell>{value.instrument}</TableCell>
                                        <TableCell style={{ textAlign:"center"}}>{value.type}</TableCell>
                                        <TableCell style={{ textAlign:"center"}}>{value.users_subscribed===null ? '0' : value.users_subscribed}</TableCell>
                                        <TableCell style={{ textAlign:"center"}}>{value.status}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
      </div>
      {
        view &&
        <AddBot hide={()=>{setView(false);}} />
      }
    </div>
  )
}

export default Bots