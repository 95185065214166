import { Button, Title } from '@tremor/react';
import styles from './healthCheck.module.scss';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { handleSuccess } from '../../global/SuccessHandling/successhandler';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

function HealthCheck() {

  const [celery, setCelery]=useState(false);
  const [celeryBeat, setCeleryBeat]=useState(false);

  async function checkCelery() {
    try {
      const response = await axios.get(`${baseUrl}/api/health/celery`);
      if(response.status===200){
        handleSuccess(response);
      }
    } catch (error) {
      handleError(error);
    }
    finally{
      setCelery(false);
    }
  }

  async function checkCeleryBeat() {
    try {
      const response = await axios.get(`${baseUrl}/api/health/celery-beat`);
      if(response.status===200){
        handleSuccess(response);
      }
    } catch (error) {
      handleError(error);
    }
    finally{
      setCeleryBeat(false);
    }
  }

  return (
    <div className={styles.healthCheck}>
      <Title className={styles.title}>Health Check</Title>
      <div className={styles.celery}>
      <Title className={styles.celeryHead}>Check Celery Status</Title>
        <div className={styles.celeryContainer}>
          <Button onClick={()=>{checkCelery(); setCelery(true)}} color='orange' variant='secondary'>{celery ? <ClipLoader color='red'size={12} /> : 'Celery'}</Button>
          <Button onClick={()=>{checkCeleryBeat(); setCeleryBeat(true)}} color='orange' variant='secondary'>{celeryBeat ? <ClipLoader color='red'size={12} /> : 'Celery Beat'}</Button>
        </div>
      </div>
    </div>
  )
}

export default HealthCheck