import { commonSuccess } from "./success";

export function handleSuccess(success:any, type?:string){
    if(type){
        commonSuccess(type);
    }
    else{
        commonSuccess(success?.data?.message);
    }

    
}