import {Route, Routes } from 'react-router-dom'; // Use 'react-router-dom', not just 'react-router'
import styles from './App.module.scss';
import Control from './global/Componnets/Control/control';
import Signal from './pages/signal_screen/signal';
import Bots from './pages/bots_screen/bots';
import Transaction from './pages/transaction_screen/transaction';
import User from './pages/user_screen/user';
import UserDetails from './pages/user_screen/userDetails';
import Setting from './pages/setting_screen/setting';
import Home from './pages/home/home';
import Login from './pages/login_screen/login';
import { useAppSelector } from './app/hooks';
import { Toaster } from 'react-hot-toast';
import HealthCheck from './pages/health_check/healthCheck';


function App() {
  const auth = useAppSelector((state) => state.authSlice);

  if(auth.login_status){
    return (
      <div className={styles.main}>
        <Control />
        <Toaster position='top-right' />
          <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/bots" element={<Bots />} />
              <Route path="/signal" element={<Signal />} />
              <Route path="/transactions" element={<Transaction />} />
              <Route path="/users" element={<User />} />
              <Route path="/users/details" element={<User />} />
              <Route path="/users/edit" element={<User />} />
              <Route path="/users/create" element={<User />} />
              <Route path="/settings" element={<Setting />} />
              <Route path="/health/check" element={<HealthCheck />} />
          </Routes>
      </div>
    );
  }
  else{
    return(
      <Login />
    )
  }

}

export default App;
