import { Button, TextInput, Title } from '@tremor/react';
import styles from './settings.module.scss';
import { Email, Password } from '../../interface/settings';
import { useState } from 'react';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { handleSuccess } from '../../global/SuccessHandling/successhandler';

function Setting() {
    const [email, setEmail]=useState<Email>()
    const [password, setPassword]=useState<Password>()
    const {data_header}=useAuthHeaders()

    async function changeEmail(email:Email) {
        try {
            const response = await axios.post(`${baseUrl}/api/admin/add-email-info`, email, data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
            }
        } catch (error) {
            handleError(error);
        }
    }

    async function changePassword(password:Password) {
        try {
            const response = await axios.post(`${baseUrl}/api/admin/change-password`, password, data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
            }
        } catch (error) {
            handleError(error);
        }
    }
    return (
        <div className={styles.settingScreen}>
            <Title className={styles.title}>Settings</Title>
            <div className={styles.email}>
                <Title className={styles.emailHead}>Email SMTP</Title>
                <div className={styles.emailInputs}>
                    <div className={styles.inputs}><Title className={styles.head}>Email</Title><TextInput onValueChange={(value)=>setEmail({email:value, password:email?.password, smtp_server:email?.smtp_server})} value={email?.email} className={styles.field} placeholder='Email' type='email' /></div>
                    <div className={styles.inputs}><Title className={styles.head}>Password</Title><TextInput onValueChange={(value)=>setEmail({email:email?.email, password:value, smtp_server:email?.smtp_server})} value={email?.password} className={styles.field} placeholder='Password' type='password' /></div>
                    <div className={styles.inputs}><Title className={styles.head}>SMTP Server</Title><TextInput onValueChange={(value)=>setEmail({email:email?.email, password:email?.password, smtp_server:value})} value={email?.smtp_server} className={styles.field} placeholder='SMTP Server' type='text' /></div>
                </div>
            </div>
            <div className={styles.email}>
                <div className={styles.emailInputs}>
                    <Button onClick={() => email && changeEmail(email)} className={styles.button} variant='secondary' color='orange'>Update Email</Button>
                </div>
            </div>
            <div className={styles.email}>
                <Title className={styles.emailHead}>Change Password</Title>
                <div className={styles.emailInputs}>
                    <div className={styles.inputs}><Title className={styles.head}>Current Password</Title><TextInput value={password?.old_password} onValueChange={(value)=>setPassword({old_password:value,new_password:password?.new_password, new_password_confirm:password?.new_password_confirm })} className={styles.field} placeholder='Current Password' type='password' /></div>
                    <div className={styles.inputs}><Title className={styles.head}>New Password</Title><TextInput value={password?.new_password} onValueChange={(value)=>setPassword({old_password:password?.old_password,new_password:value, new_password_confirm:password?.new_password_confirm })} className={styles.field} placeholder='Password' type='password' /></div>
                    <div className={styles.inputs}><Title className={styles.head}>Confirm Password</Title><TextInput value={password?.new_password_confirm} onValueChange={(value)=>setPassword({old_password:password?.old_password,new_password:password?.new_password, new_password_confirm:value })} className={styles.field} placeholder='Confirm Password' type='password' /></div>
                </div>
            </div>
            <div className={styles.email}>
                <div className={styles.emailInputs}>
                    <Button onClick={() => password && changePassword(password)} className={styles.button} variant='secondary' color='orange'>Change Password</Button>
                </div>
            </div>
        </div>
    )
}

export default Setting