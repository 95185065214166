import React, { useEffect, useState } from 'react'
import styles from './user.module.scss';
import { Button, Card, DatePicker, Select, SelectItem, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput, Title } from '@tremor/react';
import { FaArrowLeft } from "react-icons/fa6";
import { NewUser } from '../../interface/user';
import { useAuthHeaders } from '../../app/header';
import { baseUrl } from '../../app/shared';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../global/ErrorHandling/errohandler';
import {handleSuccess} from '../../global/SuccessHandling/successhandler';

interface UserDetailsProps {
    hide?: () => void;
    add?:boolean
    userDetails?:any
    edit?:boolean
}

const isNewUserValid = (user: Partial<NewUser> | undefined): user is NewUser => {
    if (!user) return false;
    return (Object.keys(user) as (keyof NewUser)[]).every(
        (key) => user[key] !== undefined && user[key] !== ""
    );
};

function formatDate(date: string): string {
    const parsedDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
        year: "numeric",
    };
    return parsedDate
        .toLocaleDateString("en-US", options)
        .replace(",", "");
}

function UserDetails({ hide , add, edit, userDetails }: UserDetailsProps ) {
    const [updatePassword, setUpdatePassword]=useState(false);
    const [newUser, setNewUser]=useState<NewUser>(edit ? userDetails : undefined);
    const {data_header}=useAuthHeaders();
    const navigate = useNavigate();
    const [newPassword, setNewPassword]=useState({
        pass:"",
        confirm:""
    })

    useEffect(() => {
        if (edit && userDetails) {
            const formattedUser = {
            ...userDetails,
            subscription_end_date: formatDate(userDetails.subscription_end_date),
            };
            setNewUser(formattedUser);
            console.log(formattedUser.subscription_end_date)
        } else {
        }
    }, [edit, userDetails]);

    async function UpdateUser(/* event: React.FormEvent, */ newUser:NewUser){
        /* event.preventDefault(); */
        console.log(newUser);
        try {
            const response = await axios.patch(`${baseUrl}/api/customer/update/${userDetails.id}`, newUser, data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
                if(hide) hide();
                navigate('/users');
            }
        } catch (error) {
            handleError(error);
        }
    }

/*     const isFormValid = () => {
        return newUser.bot_name && newUser.type && newUser.instrument;
    }; */

    async function updatePasswordApi() {
        try {
            const response = await axios.put(`${baseUrl}/api/admin/change-user-password`,{customer_id:userDetails.id, new_password:newPassword.pass}, data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
                setUpdatePassword(false);
                if(hide) hide();
                navigate('/users');
            }
        } catch (error) {
            handleError(error);
        }
    }


    async function CreateUser(newUser:NewUser){
        
        console.log('called')
        try {
            const response = await axios.post(`${baseUrl}/api/customer/add`, newUser, data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
                navigate('/users');
                if(hide) hide();
            }
        } catch (error:any) {
            handleError(error);
        }
    }
    return (
        <div className={styles.userDeatils}>
            <div><Link to={'/users'}><Button onClick={()=>{if(hide) hide();}} color='orange' variant='light' icon={FaArrowLeft}>Back</Button></Link></div>
            {
                add ?
                <div className={styles.add}>
                    <form /* onSubmit={(event)=>{event.preventDefault(); edit ? UpdateUser(newUser) : CreateUser(newUser)}} */ className={styles.inputs}>
                        <div className={styles.inputSet}>
                            <div style={{width:"30%"}} className={styles.field}>
                                <Title className={styles.fieldName}>User Name</Title>
                                <TextInput required value={newUser?.name} onValueChange={(value)=>{
                                    setNewUser((prev: any) => ({
                                        ...prev,
                                        name:value,
                                    }))
                                }} className={styles.inputField} placeholder='User Name' />
                            </div>
                            <div style={{width:"35%"}} className={styles.field}>
                                <Title className={styles.fieldName}>Phone</Title>
                                <TextInput required value={newUser?.phone} onValueChange={(value)=>{
                                    setNewUser((prev: any) => ({
                                        ...prev,
                                        phone:value,
                                    }))
                                }} className={styles.inputField} placeholder='Phone' />
                            </div>
                        </div>
                        <div  className={styles.inputSet}>
                            <div style={{width:"30%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Email</Title>
                                <TextInput required value={newUser?.email} onValueChange={(value)=>{
                                    setNewUser((prev: any) => ({
                                        ...prev,
                                        email:value,
                                    }))
                                }} className={styles.inputField} placeholder='Email' />
                            </div>
                            <div style={{width:"35%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>PAN</Title>
                                <TextInput required value={newUser?.pan_no} onValueChange={(value)=>{
                                    setNewUser((prev: any) => ({
                                        ...prev,
                                        pan_no:value,
                                    }))
                                }} className={styles.inputField} placeholder='PAN' />
                            </div>
                        </div>
                        <div  className={styles.inputSet}>
                            <div style={{width:"30%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Password</Title>
                                <TextInput disabled={edit}  value={newUser?.password} type='password' onValueChange={(value)=>{
                                    setNewUser((prev: any) => ({
                                        ...prev,
                                        password:value,
                                    }))
                                }} className={styles.inputField} placeholder='Password' />
                            </div>
                            <div style={{width:"35%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Country</Title>
                                <TextInput required value={newUser?.country} onValueChange={(value)=>{setNewUser((prev: any) => ({
                                        ...prev,
                                        country:value,
                                    }))}} className={styles.inputField} placeholder='Country' />
                            </div>
                        </div>
                        <div  className={styles.inputSet}>
                            <div style={{width:"30%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Subscription</Title>
                                <Select required value={newUser?.subscription_type} onValueChange={(value)=>setNewUser((prev: any) => ({
                                        ...prev,
                                        subscription_type:value,
                                    }))} className={styles.inputField} placeholder='Select Subscription'>
                                    <SelectItem value="Basic">BASIC</SelectItem>
                                    <SelectItem value="Gold">GOLD</SelectItem>
                                    <SelectItem value="Silver">SILVER</SelectItem>
                                    <SelectItem value="Premium">PREMIUM</SelectItem>
                                </Select>
                            </div>
                            <div style={{width:"35%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Subscription End Date</Title>
                                <DatePicker
                                    value={edit ? undefined : newUser?.subscription_end_date ? new Date(newUser.subscription_end_date) : undefined}
                                    onValueChange={(newValue) => {
                                        if (newValue instanceof Date) {
                                            const data: string = newValue.toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                            }).replace(',', '');
                                            setNewUser((prev: any) => ({
                                                ...prev,
                                                subscription_end_date: data,
                                            }));
                                        }}}
                                    className={styles.inputField} placeholder='Subscription End Date' />
                            </div>
                        </div>
                        <div  className={styles.inputSet}>
                            <div style={{width:"30%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>Address</Title>
                                <TextInput required value={newUser?.address} onValueChange={(value)=>{setNewUser((prev: any) => ({
                                        ...prev,
                                        address:value,
                                    }))}} className={styles.inputField} placeholder='Address' />
                            </div>
                            <div style={{width:"35%"}}  className={styles.field}>
                                <Title className={styles.fieldName}>GST</Title>
                                <TextInput required value={newUser?.gst_no} onValueChange={(value)=>{setNewUser((prev: any) => ({
                                        ...prev,
                                        gst_no:value,
                                    }))}} className={styles.inputField} placeholder='GST' />
                            </div>
                        </div>
                        <div  className={styles.inputSet}>
                            <div style={{width:"30%", marginTop:"2rem"}}  className={styles.field}>
                                <Button  type='submit'  onClick={(event) =>{event.preventDefault(); /* isNewUserValid(newUser) */true ? edit ? UpdateUser(newUser) : CreateUser(newUser) : console.log('user is not complete');
                                    }} color='orange' variant='secondary'>{edit ? 'Update': 'Create User'}</Button>
                                <Button type='button' onClick={()=>setUpdatePassword(true)} color='orange' variant='secondary'>Update Password</Button>
                            </div>
                        </div>
                    </form>
                </div>
                :
                <div className={styles.details}>
                    <Title className={styles.detailsHead}>User Details</Title>
                    <div className={styles.userData}>
                        <Title className={styles.data}><div className={styles.userDetailsName}>Name</div> : <div className={styles.userDetailsName}>{userDetails?.name}</div></Title>
                        <Title className={styles.data}><div className={styles.userDetailsName}>Phone</div> : <div className={styles.userDetailsName}>{userDetails?.phone}</div></Title>
                        <Title className={styles.data}><div className={styles.userDetailsName}>Email</div> : <div className={styles.userDetailsName}>{userDetails?.email}</div></Title>
                        <Title className={styles.data}><div className={styles.userDetailsName}>Subscription</div> : <div className={styles.userDetailsName}>{userDetails?.subscription_type}</div></Title>
                        <Title className={styles.data}><div className={styles.userDetailsName}>Subscription Expiry</div> : <div className={styles.userDetailsName}>{userDetails?.subscription_end_date}</div></Title>
                    </div>
                    <Title className={styles.detailsHead}>Subscribed Bots</Title>
                    <div className={styles.userBotTable}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Bots</TableHeaderCell>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Instrument</TableHeaderCell>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Quantity</TableHeaderCell>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Trades</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userDetails?.subscribed_bots.map((item:any)=>(
                                        <TableRow key={item}>
                                            <TableCell>{item.bot_name}</TableCell>
                                            <TableCell>{item.signal_name}</TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <Title className={styles.detailsHead}>Brokers</Title>
                    <div className={styles.userBotTable}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>BRoker</TableHeaderCell>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Primary</TableHeaderCell>
                                    <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Authorized</TableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    userDetails?.added_brokers?.map((item:any)=>(
                                        <TableRow key={item}>
                                            <TableRow>{item.broker_name}</TableRow>
                                            <TableRow>{item.is_primary}</TableRow>
                                            <TableRow></TableRow>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
            }
            {
                updatePassword &&
                <Card className={styles.update}>
                    <TextInput onValueChange={(value)=>setNewPassword({
                        ...newPassword,
                        pass:value
                    })} type='password' placeholder='New Password'/>
                    <TextInput onValueChange={(value)=>setNewPassword({
                        ...newPassword,
                        confirm:value
                    })} placeholder='Confirm Password' />
                    <div className={styles.buttons}>
                        <Button onClick={()=>{setUpdatePassword(false); setNewPassword({pass:"", confirm:''})}} color='gray' variant='primary'>Cancel</Button>
                        <Button onClick={()=>updatePasswordApi()} disabled={newPassword.pass==="" || newPassword.pass !== newPassword.confirm} color='orange' variant='secondary'>Update</Button>
                    </div>
                </Card>
            }

        </div>
    )
}

export default UserDetails