import styles from './bots.module.scss';
import { Button, Select, SelectItem, TextInput, Title } from '@tremor/react';
import { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa6";
import { initial_state_newBot, NewBot } from '../../interface/Bot';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { handleSuccess } from '../../global/SuccessHandling/successhandler';
import instruments from './instruments.json';

interface UserDetailsProps {
    hide: () => void;
}

function AddBot({ hide }: UserDetailsProps ) {
    const [newBot, setNewBot]=useState<NewBot>(initial_state_newBot);
    const {data_header}=useAuthHeaders();

    const isFormValid = () => {
        return newBot.bot_name && newBot.type && newBot.instrument;
    };
    
    async function createBot(new_bot_state:NewBot){
        try {
            const response = await axios.post(`${baseUrl}/api/admin/add-bot`,[new_bot_state], data_header);
            if(response.status===200 || response.status===201){
                handleSuccess(response);
                hide();
            }
        } catch (error) {
            handleError(error);
        }
    }
    return (
        <div className={styles.addBot}>
            <div><Button onClick={()=>{hide()}} color='orange' variant='light' icon={FaArrowLeft}>Back</Button></div>
            <form onSubmit={(event) => {
        // Prevent only the page reload behavior
        event.preventDefault();

        // Perform custom validation and submit logic
        if (isFormValid()) {
            createBot(newBot);
        }
    }} className={styles.details}>
                <Title className={styles.head}>Bots &gt; Add New</Title>
                <div className={styles.inputs}>
                    <div className={styles.inputSet}>
                        <div style={{width:"30%"}} className={styles.field}>
                            <Title className={styles.fieldName}>Name</Title>
                            <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                bot_name:value
                            })}} className={styles.inputField} placeholder='Bot name' />
                        </div>
                        <div style={{width:"35%"}} className={styles.field}>
                            <Title className={styles.fieldName}>Type</Title>
                            {/* <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                type:value
                            })}} className={styles.inputField} placeholder='Standard' /> */}
                            <Select required={true}  onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                type:value
                            })}} className={styles.inputField} placeholder='Select Subscription Type'>
                                    <SelectItem value="Basic">BASIC</SelectItem>
                                    <SelectItem value="Gold">GOLD</SelectItem>
                                    <SelectItem value="Silver">SILVER</SelectItem>
                                    <SelectItem value="Premium">PREMIUM</SelectItem>
                                </Select>
                        </div>
                    </div>
                    <div  className={styles.inputSet}>
                        <div style={{width:"30%"}}  className={styles.field}>
                            <Title className={styles.fieldName}>Instruments</Title>
                            {/* <TextInput required={true} onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                instrument:value
                            })}} className={styles.inputField} placeholder='Instruments name' /> */}
                            <Select className={styles.inputField} required={true} placeholder='Instruments name' onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                instrument:value
                            })}}>
                                {
                                    instruments.map(items=>(
                                        <SelectItem value={items}>{items}</SelectItem>
                                    ))
                                }
                                
                            </Select>
                        </div>
                        <div style={{width:"35%"}}  className={styles.field}>
                            <Title className={styles.fieldName}>Registered Script</Title>
                            <TextInput disabled /* onValueChange={(value)=>{setNewBot({
                                ...newBot,
                                type:value
                            })}} */ className={styles.inputField} placeholder='Select from the dropdown' />
                        </div>
                    </div>
                </div>
                <div>
                    <Button type='submit' /* onClick={(event)=>{ event.stopPropagation();  isFormValid() && createBot(newBot);}} */ color='orange' variant='secondary'>Save Bot</Button>
                </div>
            </form>
        </div>
    )
}

export default AddBot