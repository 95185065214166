import { Button, Select, SelectItem, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, TextInput } from '@tremor/react';
import styles from './user.module.scss';
import { IoSearchSharp } from 'react-icons/io5';
import { FiPlus } from 'react-icons/fi';
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import tableData from './user.json';
import UserDetails from './userDetails';
import { useEffect, useState } from 'react';
import { UserFilter, UserResponse } from '../../interface/user';
import axios from 'axios';
import { baseUrl } from '../../app/shared';
import { useAuthHeaders } from '../../app/header';
import { Link } from 'react-router-dom';
import { handleError } from '../../global/ErrorHandling/errohandler';
import { useEnterKey } from '../../global/Componnets/utils/enterAction';
import { error } from 'console';
import { handleSuccess } from '../../global/SuccessHandling/successhandler';

function User() {
    const [view, setView]=useState({
      view:false,
      type:{
        add:false,
        view:false,
        edit:false
      }
    });

    const [User, setUser]=useState<UserResponse>();
    const {data_header}=useAuthHeaders();
    const [paramFilter, setParam]=useState<UserFilter>();
    const [editUser, setEditUser]=useState<string>();

    async function GetUserResponse({param, page}:UserFilter) {
      var url = `${baseUrl}/api/admin/get-customer-details?&page=${page}&size=10`;
      if (param) url += `&param=${encodeURIComponent(param)}`;
      try {
        const response = await axios.get(url, data_header);
        if(response.status===200 || response.status===201){
          setUser(response.data);
        }
      } catch (error) {
        handleError(error);
      }
    }

    useEnterKey(() => GetUserResponse({param:paramFilter?.param, page:User?.current_page}));

    const deleteUser = async (id: string)=>{
      try{
        const response = await axios.delete(`${baseUrl}/api/customer/${id}`,
        data_header,
        );
        if(response.status===200 || response.status===201){
          GetUserResponse({param:undefined,page:1});
          handleSuccess(response)
        }
      }catch(error){
        handleError(error);
      }
    }

    useEffect(()=>{
      GetUserResponse({param:undefined,page:1});
    },[])

    useEffect(()=>{
      paramFilter?.param==="" && GetUserResponse({param:undefined,page:1});
    },[paramFilter?.param])

  return (
    <div className={styles.user}>
      <div className={styles.filters}>
        <div className={styles.search}>
          <TextInput onValueChange={(value)=>setParam({param:value,page:User?.current_page})} placeholder='Search By Name, Mobile Number, Email'/>
          <Button onClick={()=>GetUserResponse({param:paramFilter?.param, page:User?.current_page})} variant='secondary' color={'orange'} icon={IoSearchSharp}>Search</Button>
        </div>
        <Link to={'/users/create'}><Button onClick={()=>setView({view:true,type:{add:true, edit:false, view:false}})} variant='secondary' color={'orange'} icon={FiPlus}>Add User</Button></Link>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.pagination}><p>Showing page {User?.current_page || 1} of {User?.total_pages || 1}</p> 
          <FaAngleLeft onClick={() => User?.current_page !== 1 && GetUserResponse({param:paramFilter?.param, page:(User?.current_page || 1)-1})} />
          <FaAngleRight onClick={() => User?.current_page !== User?.total_pages && GetUserResponse({param:paramFilter?.param, page:(User?.current_page || 1)+1})} />
        </div>
        <div className={styles.table}>
                    <Table className={styles.mainTable}>
                        <TableHead className={styles.tableHead}>
                            <TableRow className={styles.tableRow}>
                                <TableHeaderCell style={{color:"#D87E60"}} className={styles.headItems}>Name</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Phone</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Email</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Status</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60"}}>Subscription</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60" , textAlign:"center"}}>Subscription Expiry</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Broker Authorize</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Edit User</TableHeaderCell>
                                <TableHeaderCell style={{color:"#D87E60", textAlign:"center"}}>Delete User</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                User?.data.map((value, index)=>(
                                    <TableRow key={index}>
                                        <Link to={'/users/details'}><TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}} >{value.name}</TableCell></Link>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}} ><Link to={'/users/details'}>{value.phone}</Link></TableCell>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}} ><Link to={'/users/details'}>{value.email}</Link></TableCell>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}} ><Link to={'/users/details'}>{value.is_deleted ? 'Inactive':'Active'}</Link></TableCell>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}}  style={{ textAlign:"center"}}><Link to={'/users/details'}>{value.subscription_type}</Link></TableCell>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}}  style={{ textAlign:"center"}}><Link to={'/users/details'}>{value.subscription_end_date}</Link></TableCell>
                                        <TableCell onClick={()=>{setView({
                                      view:true, type:{add:false, edit:false, view:true}
                                    }); setEditUser(value.id)}} style={{ textAlign:"center"}}><Link to={'/users/details'}>{value.broker_validated===null ? 'No Authorized Broker' : value.broker_validated}</Link></TableCell>
                                        <TableCell style={{ textAlign:"center"}}><Link to={'/users/edit'}><Button onClick={()=>{setEditUser(value.id); setView({view:true, type:{add:true,edit:true,view:false}}); }} color='green' style={{padding:'2px 8px'}} variant='secondary'>Edit</Button></Link></TableCell>
                                        <TableCell style={{ textAlign:"center"}}><Button onClick={()=>deleteUser(value.id)} color='red' style={{padding:'2px 8px'}} variant='secondary'>Delete</Button></TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
      </div>
      {
        view.view &&
        <UserDetails userDetails={User?.data.find(item=> item.id ===editUser)} edit={view.type.edit || view.type.view} add={view.type.add} hide={()=>{setView({view:false,type:{add:false, edit:false, view:false}}); GetUserResponse({param:undefined,page:(User?.current_page || 1)});}} />
      }
    </div>
  )
}

export default User